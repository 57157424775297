import { Field, Form, Formik } from 'formik';
import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import WsStaticImage from '@/components/common/WsStaticImage';
import { AppDispatch, RootState } from '@/store';
import {
  checkPincode,
  isUserAlreadyPresent,
} from '@/store/features/commonSlice';
import { addSignupLead } from '@/store/features/crmSlice';
import {
  hideLogin,
  setHideFistSignUp,
  setSignUpData,
  showLoginModal,
  showSignUp,
} from '@/store/features/loginSlice';
import { phoneRegex, postRegex } from '@/utils/commonValidations';
import { useMediaQuery } from '@react-hook/media-query';
import styles from './style.module.scss';
const WsButton = dynamic(() => import('@/components/common/WsButton'));
const WsPopup = dynamic(() => import('@/components/common/WsPopup'));

interface FastModalProps {
  isShow: boolean;
}
const validationSchema = yup.object().shape({
  pincode: yup
    .string()
    .trim()
    .required('Kindly provide a valid pincode')
    .matches(postRegex, 'Please enter a valid Pincode')
    .test('len', 'Pincode Must be exactly 6 characters', (val: any) => {
      return val?.length == 6;
    }),
  telephone: yup
    .string()
    .trim()
    .nullable()
    .required('Kindly provide a valid mobile number')
    .matches(phoneRegex, 'Kindly provide a valid mobile number')
    .test('len', 'Phone Number Must be exactly 10 characters', (val: any) => {
      return val ? val?.length == 10 : true;
    }),
});

const FastModal: React.FC<FastModalProps> = ({ isShow }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [city, setCity] = useState('');
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setShowModal] = useState(false);
  const [cityText, setCityText] = useState<string>('');
  const [isPhoneExist, setPhoneExist] = useState(false);
  const [pinErrors, setPinErrors] = useState<string>('');
  const [initialValues, setInit] = useState({ pincode: '', telephone: '' });
  const isMobile = useMediaQuery('only screen and (max-width: 767px)');
  const { showFistSignUp } = useSelector(
    (state: RootState) => state?.loginModal
  );
  useEffect(() => {
    // if (isShow) {const flag = sessionStorage.getItem('signup');
    // if (!flag) setShowModal(true); else setShowModal(false);}
    setShowModal(isShow);
  }, [isShow]);
  const onSubmit = async (
    formData: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      if (!city || pinErrors || isPhoneExist) return;
      setLoading(true);
      await dispatch(addSignupLead({ ...formData, city })).then((res: any) => {
        if (res?.payload?.code === 200) {
          dispatch(setSignUpData(formData));
          handleSignupOpen();
          setSubmitting(false);
        } else {
          const msg = res?.error?.message || 'Something went wrong!!';
          setPinErrors(msg);
        }
      });
    } catch (error) {
      console.log('Account edit error', error);
    }
    setLoading(false);
  };
  const handleSignupOpen = () => {
    dispatch(setHideFistSignUp());
    dispatch(showLoginModal());
    dispatch(hideLogin());
    dispatch(showSignUp());
  };
  useEffect(() => {
    setInit({ pincode: '', telephone: '' });
    if (!isMobile && showFistSignUp) handleSignupOpen();
    else setShowModal(showFistSignUp);
  }, [showFistSignUp]);

  const handleToggle = () => {
    dispatch(setHideFistSignUp());
    setShowModal(!isModalOpen);
  };
  const handlepin = async (pincode: any) => {
    try {
      setCity('');
      setCityText('');
      setLoading(true);
      const checkPin = await dispatch(checkPincode({ pincode })).then(
        (res: any) => {
          if (res?.payload?.data?.data) return res?.payload?.data?.data;
          else setPinErrors('Delivery option not available on requested area.');
        }
      );
      if (checkPin?.pincodeData?.city) {
        const city = checkPin?.pincodeData?.city ?? '';
        const state = checkPin?.pincodeData?.state ?? '';
        const stateTxt = state?.split('_')?.[0] ?? '';
        const text = city + ', ' + stateTxt;
        setCity(city);
        setCityText(text);
      }
    } catch (error) {
      console.log('Account edit error', error);
    }
    setLoading(false);
  };
  const checkPhone = async (phone: any) => {
    const isExist = await dispatch(isUserAlreadyPresent(phone)).then(
      (response: any) =>
        response?.payload?.code == 200 &&
        Object.keys(response?.payload?.data?.user)?.length !== 0
    );
    setPhoneExist(isExist);
  };
  return (
    <WsPopup isOpen={isModalOpen} onClose={handleToggle}>
      <div
        className={`${styles['fastfilter-box']} ${styles[showFistSignUp ? 'open' : 'close']}`}
      >
        <p className={styles['close-filter-fast']} onClick={handleToggle}>
          <WsStaticImage
            src={'/images/category/modal-close-img.svg'}
            alt="media"
            width={16}
            height={16}
          />
        </p>
        <WsStaticImage
          src="/images/login/sign-up-m.jpg"
          alt="sign-up"
          quality={75}
          width={400}
          height={300}
          // loading="lazy"
        />
        {/* key={'sign-up'} quality={75} width={599} height={400}// loading="lazy" unoptimized={false} */}{' '}
        <Formik
          onSubmit={onSubmit}
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ errors, values, touched, handleChange }) => (
            <Form className={styles.field__form}>
              <div className={styles.formgroup}>
                <label
                  htmlFor="telephone"
                  className={values.telephone != '' ? styles.field__label : ''}
                >
                  Phone Number
                </label>
                <Field
                  type="tel"
                  id="telephone"
                  key="telephone"
                  name="telephone"
                  placeholder="Phone Number"
                  className={
                    errors.telephone && touched.telephone
                      ? styles.texterror
                      : ''
                  }
                  maxLength={10}
                  onChange={(e: any) => {
                    setPinErrors('');
                    const text = e.target.value;
                    const numericValue = text.replace(/[^0-9]/g, '');
                    numericValue.length < 11 &&
                      handleChange('telephone')(numericValue);
                    numericValue.length === 10 && checkPhone(numericValue);
                  }}
                />
                {errors.telephone && touched.telephone && (
                  <p>{errors.telephone}</p>
                )}
                {isPhoneExist && <p>Phone number already exist.</p>}
              </div>
              <div className={styles.formgroup}>
                <label
                  htmlFor="pincode"
                  className={values.pincode != '' ? styles.field__label : ''}
                >
                  Pincode
                </label>
                <Field
                  name="pincode"
                  id="pincode"
                  type="number"
                  maxLength={6}
                  placeholder="Enter Pincode"
                  className={
                    errors.pincode && touched.pincode ? styles.texterror : ''
                  }
                  onChange={(e: any) => {
                    setPinErrors('');
                    const text = e.target.value;
                    const numericValue = text.replace(/[^0-9]/g, '');
                    numericValue.length < 7 &&
                      handleChange('pincode')(numericValue);
                    numericValue.length === 6 && handlepin(numericValue);
                  }}
                />
                <span>{cityText}</span>
                {errors.pincode && touched.pincode && <p>{errors.pincode}</p>}
                {pinErrors && <p>{pinErrors}</p>}
              </div>
              <div className={styles.btngroup}>
                <WsButton
                  type="submit"
                  disabled={loading}
                  className={styles.updatebutton}
                >
                  CONTINUE
                </WsButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </WsPopup>
  );
};

export default FastModal;
